import React, { Component } from "react";
import "./brand.css";

class Header extends Component {
  render() {
    return (
      <div
        id="branding-bar"
        className="iu"
        itemScope="itemscope"
        itemType="http://schema.org/CollegeOrUniversity"
        role="complementary"
        aria-labelledby="campus-name"
      >
        <div className="row pad">
          <img src="//assets.iu.edu/brand/3.x/trident-large.png" alt="IU" />
          <p id="iu-campus">
            <a href="https://www.iu.edu" title="Indiana University">
              <span
                id="campus-name"
                className="show-on-desktop"
                itemProp="name"
              >
                Indiana University
              </span>
              <span className="show-on-tablet" itemProp="name">
                Indiana University
              </span>
              <span className="show-on-mobile" itemProp="name">
                IU
              </span>
            </a>
          </p>
        </div>
      </div>
    );
  }
}
export default Header;