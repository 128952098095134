import React, { Component } from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import MessagingForm from './components/MessagingForm';
import axios from 'axios';

class App extends Component {
  constructor() {
    super();
    this.state = {
      userID: '',
      admins: ''

    };
  }

  componentDidMount() {
    //client-side authentication components here
    if (!this.state.userID) {
      const curURL = window.location.href;

      const ticket = this.getParameterByName("ticket", curURL);
      if (!ticket) {
          var url =
              process.env.REACT_APP_IULOGIN +
              "idp/profile/cas/login?service=" +
              process.env.REACT_APP_CLIENT_URL;
          window.location.assign(url);
          return;
      }

      axios.all([axios.get(
              process.env.REACT_APP_SERVER_URL + "/api/validate",
              {
                  params: {
                      ticket: ticket
                  },
                  headers: {
                      "Content-Security-Policy": "default-src 'self'"
                  }
              }
          ),
          axios.get(process.env.REACT_APP_SERVER_URL +'/getadmins')])
          .then(axios.spread((res,admin_res) =>
              {
                  console.log("Verification response: " + JSON.stringify(res.data));
                  sessionStorage.setItem("jwt_id", res.data.jwt);
                  sessionStorage.setItem("user_id", res.data.uid)
                  this.setState({userID: res.data.uid});
                  this.setState({admins: process.env.REACT_APP_ADMINS});
                  console.log("Logged in " + this.state.userID);
                  // var testStorage = sessionStorage.getItem("user_id");
                  // console.log("testing storage: " + testStorage)
                  // console.log("admins are: " +this.state.admins)
              }
          )).catch((error,admin_error)=> {
          // handle error
          alert("Error");
          alert("Error");
      });
    }
  }

  getParameterByName = (name, curURL) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(curURL);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  render() {
      console.log("Rendering App");
    return (
      <div>
        <Header />
        <br/>
        <div >
          {this.state.admins.includes(this.state.userID) ? (
            <MessagingForm />
          ): <h4>Authenticating</h4>}
        
        </div>
        <Footer />

      </div>
    )
  }



}




// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
