import React, { Component } from 'react';
import axios from 'axios';
import './MessagingForm.css'

class MessagingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systems: [
                {
                    value: "Quartz",
                    isChecked:false,
                    emailAddress: process.env.REACT_APP_QUARTZ_MAIL_LIST,
                    slackURL: process.env.REACT_APP_QUARTZ_SLACK_URL
                },
                {
                    value: "Big Red 200",
                    isChecked:false,
                    emailAddress: process.env.REACT_APP_BIGRED200_MAIL_LIST,
                    slackURL: process.env.REACT_APP_BIGRED200_SLACK_URL
                },
                {
                    value: "Research Desktop",
                    isChecked:false,
                    emailAddress: process.env.REACT_APP_RED_MAIL_LIST,
                    slackURL: process.env.REACT_APP_RED_SLACK_URL
                },
                {
                    value: "SDA",
                    isChecked:false,
                    emailAddress: process.env.REACT_APP_SDA_MAIL_LIST,
                    slackURL: process.env.REACT_APP_SDA_SLACK_URL
                },
                {
                    value: "Geode-Project",
                    isChecked:false,
                    emailAddress: process.env.REACT_APP_GEODE_MAIL_LIST,
                    slackURL: process.env.REACT_APP_GEODE_SLACK_URL
                },
                {
                    value: "Test System",
                    isChecked:false,
                    emailAddress: "",
                    slackURL: process.env.REACT_APP_TEST_SLACK_URL
                }
            ],
            systemsSelected: [],
            systemsWithoutSlackSelected: [],
            systemsWithoutEmailSelected: [],
            messageText: "",
            mailingList: false,
            slackMessage: false,
            disableSlackMessage: false,
            disableEmailMessage: false,
            user_id: "",
            emailSubjectLine: "Maintenance Notification",
            // previewEmail: "",
            // previewSlackMessage: "",
            touched: {
                mailingList: false,
                slackMessage: false
            }
        };
    } //end props
    
    componentDidMount() {
        this.state.user_id = sessionStorage.getItem("user_id");
        // console.log("testing userid sessionstorage from messagingform: " + this.state.user_id);
    }

    handleClick = (field) => () => {
        this.setState({
          touched: { ...this.state.touched, [field]: true },
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (!this.state.systemsSelected.length) {
            alert("Please select a system.");
            return;
        }

        if (!this.state.messageText) {
            alert("Please enter a status message.");
            return;
        }

        if (!this.state.mailingList && !this.state.slackMessage) {
            alert("Please select an option to send the message to.");
            return;
        }

        if (this.state.mailingList && !this.state.emailSubjectLine) {
            alert("Please enter an email subject line.");
            return;
        }
        
        var record = {};
        record["systemsSelected"] = this.state.systemsSelected;
        record["messageText"] = this.state.messageText;
        record["mailingList"] = this.state.mailingList;
        record["slackMessage"] = this.state.slackMessage;

        console.log("sending data: " + JSON.stringify(record));

        let systemsSelected = this.state.systemsSelected;
        let systems = this.state.systems;
        let emailAddress = "";
        let slackURL = "";
        let errorMessage = ""
        for (let i = 0; i < systemsSelected.length; i++) {
            let systemSelected = systemsSelected[i];
            for (let j = 0; j < systems.length; j++) {
                let system = systems[j];
                if (systemSelected === system.value) {
                    emailAddress = system.emailAddress;
                    slackURL = system.slackURL;
                    break;
                }
            }

            if (this.state.mailingList){
                await axios.post(process.env.REACT_APP_SERVER_URL + "/api", {
                    subjectLine: "[" + systemSelected + "] " + this.state.emailSubjectLine,
                    text: "Hello, \n\n" + this.state.messageText,
                    receiver: emailAddress,
                    sender: this.state.user_id + "@iu.edu"
                })
                .then(function (res) {
                    console.log(res.data);
                })
                .catch(function (err) {
                    errorMessage += "Email error - " + systemSelected + " - " + err.response.data + "\n";
                })
            }

            if (this.state.slackMessage) {
                await axios.post( process.env.REACT_APP_SERVER_URL + "/sendSlackNotificationPython", {
                        text: this.state.messageText,
                        url: slackURL,
                    },
                )
                .then(function (res) {
                    console.log(res.data);
                })
                .catch(function (err) {
                    errorMessage += "Slack error - " + systemSelected + " - " + err.response.data + "\n";
                })
            }
        }

        if (errorMessage.length) {
            alert(errorMessage);
            return;
        }
        
        var divsToHide = document.getElementsByClassName("main"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
          divsToHide[i].style.display = "none"; // depending on what you're doing
        }
        var divsToShow = document.getElementsByClassName("success"); //divsToHide is an array
        for(i = 0; i < divsToShow.length; i++){
          divsToShow[i].style.display = "block"; // depending on what you're doing
        }
    }

    handleBlur = (field) => (evt) => {
        this.setState({
          touched: { ...this.state.touched, [field]: true },
        });
      }

    handleAllSystemsChecked = event => {
        let systems = this.state.systems;
        let systemsSelected = [];
        systems.forEach(system => {
            system.isChecked = event.target.checked
            if (event.target.checked === true) {
                systemsSelected.push(system.value);
            }
        });
        this.setState({
            systems: systems,
            systemsSelected: systemsSelected
        });

        this.checkIfSelectedSystemsHaveSlack(systemsSelected);
        this.checkIfSelectedSystemsHaveEmail(systemsSelected);
    } 

    handleCheckSystem = (event) => {
        let systems = this.state.systems;
        let systemsSelected = this.state.systemsSelected;
        systems.forEach(systems => {
            if (systems.value === event.target.name) {
                systems.isChecked = event.target.checked

                let index = systemsSelected.indexOf(systems.value);
                // Adding systems to the list.
                if (index < 0 && event.target.checked) {
                    systemsSelected.push(systems.value);
                }
                // Removing systems from the list.
                if (index > -1 && !event.target.checked) {
                    systemsSelected.splice(index, 1);
                }
            }
        });
        this.setState({
            systems: systems,
            systemsSelected: systemsSelected
        });

        this.checkIfSelectedSystemsHaveSlack(systemsSelected);
        this.checkIfSelectedSystemsHaveEmail(systemsSelected);
    }

    handleMessageTextChange = (event) => {
        this.setState({ messageText: event.target.value });

    }

    handleEmailSubjectLineChange = (event) => {
        this.setState({ emailSubjectLine: event.target.value });
    }

    handleTestEmailChange = (event) => {
        let systems = this.state.systems;
        systems.forEach((row) => {
            if (row.value === "Test System") {
                row.emailAddress = event.target.value;
            }
        });
        this.setState({ systems: systems });
    }

    handleSlackMessage = event =>{
        this.setState({ slackMessage: event.target.checked})
    }

    handleMailingList = event => {
        this.setState({ mailingList: event.target.checked})
    }

    disableSlackMessage = (bool) => {
        if (bool) {
            this.setState({ slackMessage: false })
        }
        this.setState({ disableSlackMessage: bool });
    }

    checkIfSelectedSystemsHaveSlack = (systemsSelected) => {
        var systemsWithoutSlackSelected = [];
        this.state.systems.forEach(system => {
            if (systemsSelected.includes(system.value)) {
                if (system.slackURL === undefined) {
                    systemsWithoutSlackSelected.push(system.value);
                }
            }
        })
        
        if (systemsWithoutSlackSelected.length > 0) {
            this.disableSlackMessage(true);
        } else {
            this.disableSlackMessage(false);
        }

        this.setState({ systemsWithoutSlackSelected: systemsWithoutSlackSelected })
    }


    disableEmailMessage = (bool) => {
        if (bool) {
            this.setState({ mailingList: false })
        }
        this.setState({ disableEmailMessage: bool });
    }

    checkIfSelectedSystemsHaveEmail = (systemsSelected) => {
        var systemsWithoutEmailSelected = [];
        this.state.systems.forEach(system => {
            if (systemsSelected.includes(system.value)) {
                if (system.emailAddress === undefined) {
                    systemsWithoutEmailSelected.push(system.value);
                }
            }
        })
        
        if (systemsWithoutEmailSelected.length > 0) {
            this.disableEmailMessage(true);
        } else {
            this.disableEmailMessage(false);
        }

        this.setState({ systemsWithoutEmailSelected: systemsWithoutEmailSelected })
    }

    render() {
        console.log("Rendering MessageForm");
        return (
            <div className="messageform container-fluid row">
                <div className="row main col-md-12">
                    <h1 className="formheader">
                        RT Status
                    </h1>
                    <div className="row col-md-10 mx-auto projectcard card rounded-0">
                        <div className="card-header">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div name="selectedsystems" className="left-sub-padding">
                                        <p>
                                            Select the affected systems you're reporting about: 
                                        </p>
                                        <input type="checkbox" onChange={this.handleAllSystemsChecked} /> All systems are affected
                                        <ul>
                                            {
                                                this.state.systems.map(systems => {
                                                    return (
                                                    <label key={systems.key}> 
                                                        <input key={systems.key} type="checkbox" value={systems.isChecked} checked={systems.isChecked} name={systems.value} onChange={this.handleCheckSystem} /> 
                                                        {systems.value}
                                                    </label>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div name="warningmessage">
                                        {
                                            this.state.systemsWithoutSlackSelected.length > 0 ? (
                                                this.state.systemsWithoutSlackSelected.length > 1 ? (
                                                    <p className="warning left-sub-padding">
                                                        Options {this.state.systemsWithoutSlackSelected.toString()} do not have Slack channels. Sending Slack messages has been disabled.
                                                    </p>
                                                ):(
                                                    <p className="warning left-sub-padding">
                                                        Option {this.state.systemsWithoutSlackSelected.toString()} does not have a Slack channel. Sending Slack messages has been disabled.
                                                    </p>
                                                )
                                            ):(
                                                <div />
                                            )
                                        }
                                    </div>
                                    <div name="emailwarningmessage">
                                        {
                                            this.state.systemsWithoutEmailSelected.length > 0 ? (
                                                this.state.systemsWithoutEmailSelected.length > 1 ? (
                                                    <p className="warning left-sub-padding">
                                                        Options {this.state.systemsWithoutEmailSelected.toString()} do not have an Email associated with them. Sending Email has been disabled.
                                                    </p>
                                                ):(
                                                    <p className="warning left-sub-padding">
                                                        Option {this.state.systemsWithoutEmailSelected.toString()} does not have an Email associated with it. Sending Email has been disabled.
                                                    </p>
                                                )
                                            ):(
                                                <div />
                                            )
                                        }
                                    </div>
                                    {
                                        this.state.systems.map(systems => {
                                            if (systems.value === "Test System" && systems.isChecked) {
                                                return (
                                                    <div name="testemail" className="left-sub-padding">
                                                        <p>
                                                            Enter a test email to send to:
                                                        </p>
                                                        <div className="left-sub-padding">
                                                            <input
                                                                name="testEmail"
                                                                type="text"
                                                                className="form-control form-control-lg"
                                                                value={systems.emailAddress}
                                                                onChange={this.handleTestEmailChange}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div />
                                                )
                                            }
                                        })
                                    }
                                    <div name="emailsubjectline" className="left-sub-padding">
                                        <p>
                                            Enter an email subject line (if applicable):
                                        </p>
                                        <div className = "left-sub-padding">
                                            <input
                                                name="emailSubjectLine"
                                                type="text"
                                                className="form-control form-control-lg"
                                                value={this.state.emailSubjectLine}
                                                onChange={this.handleEmailSubjectLineChange}
                                            />
                                        </div> 
                                    </div>
                                    <div name="messagetext" className="left-sub-padding">
                                        <p>
                                            Enter your status message:
                                        </p>
                                        <div className="left-sub-padding">
                                            <textarea
                                                name="messageText"
                                                type="text"
                                                className="form-control form-control-lg"
                                                onBlur={this.handleBlur('messageText')}
                                                value={this.state.messageText}
                                                onChange={this.handleMessageTextChange}
                                                rows={10}
                                                cols={100}
                                                /> 
                                        </div>
                                    </div>
                                    <div name="sendto" className="left-sub-padding">
                                        <p>Send message to:</p>
                                        <ul>
                                            <label>
                                                <input 
                                                    type="checkbox" 
                                                    id="slack" 
                                                    value={this.state.slackMessage}
                                                    checked={this.state.slackMessage}
                                                    onChange={this.handleSlackMessage}
                                                    disabled={this.state.disableSlackMessage}
                                                />
                                                Slack
                                            </label>
                                            <label>
                                                <input 
                                                    type="checkbox" 
                                                    id="email" 
                                                    value={this.state.mailingList}
                                                    checked={this.state.mailingList}
                                                    onChange={this.handleMailingList}
                                                    disabled={this.state.disableEmailMessage}
                                                />
                                                E-Mail
                                            </label>
                                        </ul>
                                    </div>
                                    {this.state.slackMessage || this.state.mailingList ? (
                                    <div name="previewmessages" className="left-sub-padding">
                                        <p>Messages will be sent to 
                                            {this.state.slackMessage ? " Slack Channels " : ""}
                                            {this.state.slackMessage & this.state.mailingList ? "and" : ""} 
                                            {this.state.mailingList ? " Mailing Lists ": ""} 
                                            for the following system(s):{" "}
                                            {this.state.systemsSelected.join(", ")}</p>
                                        {this.state.slackMessage ? (
                                            //this should appear similarly to how the slack message will appear
                                            <div>
                                                <p>Your Slack message will appear as:</p>
                                                <div className="left-sub-padding">
                                                    <textarea
                                                        name="messageText"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        onBlur={this.handleBlur('messageText')}
                                                        value={this.state.messageText}
                                                        onChange={this.handleMessageTextChange}
                                                        rows={5}
                                                        cols={100}
                                                        readOnly
                                                    />
                                                </div>
                                                {/*<p className="left-sub-padding">{this.state.messageText}</p>*/}
                                            </div>
                                        ):(<br/>)
                                        }
                                        {this.state.mailingList ? (
                                            //this should appear similarly to how we format the email
                                            <div>
                                                <p>Your e-mails will appear as:</p>
                                                <div className="left-sub-padding">
                                                    <input
                                                        style={{width: "735px"}}
                                                        name="emailSubjectLine"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        value={"[<system>] " + this.state.emailSubjectLine}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="left-sub-padding">
                                                    <textarea
                                                        name="messageText"
                                                        type="text"
                                                        className="form-control form-control-lg"
                                                        onBlur={this.handleBlur('messageText')}
                                                        value={"Hello,\n\n" + this.state.messageText}
                                                        onChange={this.handleMessageTextChange}
                                                        rows={7}
                                                        cols={100}
                                                        readOnly
                                                    />
                                                </div>
                                                <br/>
                                                {/*<p className="left-sub-padding">Hello, <br/><br/>{this.state.messageText}</p>*/}
                                            </div>
                                        ):(<br/>)
                                        }
                                    </div> 
                                    ):<br/>
                                    }
                                    <div className="left-sub-padding">
                                        <button
                                            type="submit"
                                            class={"btn btn-sm"}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        >
                                            SEND MESSAGE
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="success">
                    <h1>Message sent.</h1>
                    <p>Click <a href={window.location.href}>here</a> to return to the RT Status page.</p>
                </div>
            </div>
        );
    }
}

export default MessagingForm;